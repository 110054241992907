<template>
  <div class="card gutter-b card-stretch card-custom">
    <div class="card-header h-auto border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="d-block text-dark font-weight-bolder"
            >Turnover</span
          >
        </h3>
        <span class="d-block text-muted mt-2 font-size-sm">
              <!-- Date : {{ dateRangeText }} -->
              <!-- prepend-icon="mdi-calendar" -->    
          <v-row align="center">
            <v-text-field
              class="m-1"
              v-model="dates[0]"
              v-on:keyup.enter="ApplyDates"
              label="From"
              filled
              rounded
              dense
            ></v-text-field>
            <v-text-field
              class="m-1"
              v-model="dates[1]"
              v-on:keyup.enter="ApplyDates"
              label="To"
              filled
              rounded
              dense
            ></v-text-field>
          </v-row>
        </span>
      <div class="card-toolbar">

        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
          no-caret
          right
          no-flip
        >
          <template v-slot:button-content>
            <slot><i class="ki ki-calendar-2"></i></slot>
          </template>
          <!--begin::Navigation-->
          <div class="navi navi-hover min-w-md-250px">
            <b-dropdown-text tag="div" class="navi-header font-weight-bold text-center">
              <a href="#" @click="lastMonth" class="btn btn-primary font-weight-bolder font-size-sm m-2">
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="media/svg/icons/Code/Right-circle.svg" />
                </span>Last month</a>
              <a href="#" @click="thisMonth" class="btn btn-primary font-weight-bolder font-size-sm m-2">
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="media/svg/icons/Code/Right-circle.svg" />
                </span>This month</a>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-header font-weight-bold">
              <!-- <span class="font-size-lg">
                Choose your date:
              </span> -->
          <v-date-picker
            v-model="dates"
            width="290"
            range
            scrollable
            no-title
          ></v-date-picker>
            <!-- no-title -->
          <!-- <v-text-field
            v-model="dateRangeText"
            label="Date range"
            prepend-icon="mdi-calendar"
            readonly
          ></v-text-field> -->
          
            </b-dropdown-text>
          </div>
        </b-dropdown>

        <a href="#" @click="ApplyDates" class="btn btn-success font-weight-bolder font-size-sm">
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="media/svg/icons/Code/Right-circle.svg" />
          </span>Apply</a>

    </div>
  </div>
    <!-- <div class="card-body d-flex flex-column p-0"> -->
      
    <!-- <v-date-picker v-model="startdate"></v-date-picker>
    <v-date-picker v-model="enddate"></v-date-picker> -->

    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <div class="card-spacer bg-white card-rounded">

      <!--begin::Items-->
      
      <div class="row row-paddingless">
          <div class="col">
            <div class="d-flex align-items-center mr-2">
              <div
                class="'symbol symbol-45 symbol-light-info mr-4 flex-shrink-0'"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-info">
                    <inline-svg src="media/svg/icons/Shopping/Chart-bar2.svg" />
                  </span>
                </div>
              </div>
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  Total
                </div>
                <div v-if="!loading" class="font-size-lg text-muted font-weight-bold mt-1">
                  Buying : {{ formatNumbers(totalBuying) }}<br>
                  Selling : {{ formatNumbers(totalSelling) }}<br>
                </div>
                <div v-if="loading" class="spinner spinner-primary spinner-md spinner-center mt-5">
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-center mr-2">
              <div
                class="'symbol symbol-45 symbol-light-info mr-4 flex-shrink-0'"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-info">
                    <inline-svg src="media/svg/icons/Shopping/Chart-pie.svg" />
                  </span>
                </div>
              </div>
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  Profit
                </div>
                <div v-if="!loading" class="font-size-lg text-muted font-weight-bold mt-1">
                  Margin : {{ formatNumbers(totalSelling - totalBuying) }}<br>
                  Percentage : {{ formatNumbers(((totalSelling - totalBuying) / totalSelling * 100).toFixed(0)) }} %<br>
                </div>
                <div v-if="loading" class="spinner spinner-primary spinner-md spinner-center mt-5"></div>
              </div>
            </div>
          </div>
      </div>

      <div class="row row-paddingless">
      <template v-for="(item, i) in headerdata">
        <!--begin::Item-->
          <div class="col" v-bind:key="i">
            <div class="d-flex align-items-center mr-2">
              <!--begin::Symbol-->
              <div
                :class="'symbol symbol-45 symbol-light-'+item.iconColor+' mr-4 flex-shrink-0'"
              >
                <div class="symbol-label">
                  <span :class="'svg-icon svg-icon-2x svg-icon-'+item.iconColor">
                    <!-- <inline-svg v-if="item.debnr === 'Flowers'" src="media/svg/icons/Home/Flower2.svg" /> -->
                    <inline-svg :src="'media/svg/icons/'+item.iconSVG" />
                  </span>
                </div>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  {{ item.debnr }}
                </div>
                <div v-if="!loading" class="font-size-lg text-muted font-weight-bold mt-1">
                  <!-- Buying : {{ formatNumbers(item.inkoop) }}<br> -->
                  Turnover : {{ formatNumbers(item.verkoop) }} ({{ formatNumbers(((item.verkoop) / totalSelling * 100).toFixed(0)) }}%)<br>
                  <!-- Inkoop : {{ formatNumbers(item.inkoop) }}<br> -->
                  Profit : {{ formatNumbers(item.verkoop - item.inkoop) }} ({{ formatNumbers(((item.verkoop - item.inkoop) / item.verkoop * 100).toFixed(0)) }}%)<br>
                </div>
                <div v-if="loading" class="spinner spinner-primary spinner-md spinner-center mt-5"></div>
              </div>
              <!--end::Title-->
            </div>
          </div>
        <!--end::Item-->
        <!-- <div class="d-flex align-items-center mb-10" v-bind:key="i">
          <div class="symbol symbol-40 symbol-light-success mr-5">
            <span class="symbol-label">
                src="media/svg/icons/Shopping/Chart-bar2.svg"
              <inline-svg
                :src="item.svg"
                class="h-75 align-self-end"                
              ></inline-svg>
            </span>
          </div>
          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
            <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
              
            </a>
            <span class="text-muted">
              
            </span>
          </div>
        </div> -->
      </template>
      </div>

        <!-- <div class="row row-paddingless mb-10">
          <div class="col">
            <div class="d-flex align-items-center mr-2">
              <div
                class="symbol symbol-45 symbol-light-info mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-info">
                    <inline-svg src="media/svg/icons/Shopping/Chart-bar2.svg" />
                  </span>
                </div>
              </div>
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  Total
                </div>
                <div class="font-size-lg text-muted font-weight-bold mt-1">
                  Buying : 36.351<br>
                  Selling : 72.514<br>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-center mr-2">
              <div
                class="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-danger">
                    <inline-svg src="media/svg/icons/Home/Flower2.svg" />
                  </span>
                </div>
              </div>
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                </div>
                <div class="font-size-lg text-muted font-weight-bold mt-1">
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-center mr-2">
              <div
                class="symbol symbol-45 symbol-light-info mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-info">
                    <inline-svg src="media/svg/icons/Home/Flower1.svg" />
                  </span>
                </div>
              </div>
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  Plants
                </div>
                <div class="font-size-lg text-muted font-weight-bold mt-1">
                  turnover : 36.351 (20%)<br>
                  profit : 12.351 (33%)<br>                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-paddingless">
          <div class="col">
            <div class="d-flex align-items-center mr-2">
              <div
                class="symbol symbol-45 symbol-light-info mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-info">
                    <inline-svg src="media/svg/icons/Shopping/Chart-pie.svg" />
                  </span>
                </div>
              </div>
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  Profit
                </div>
                <div class="font-size-lg text-muted font-weight-bold mt-1">
                  Margin : 36.351<br>
                  Percentage : 45%<br>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-center mr-2">
              <div
                class="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-success">
                    <inline-svg src="media/svg/icons/Layout/Layout-left-panel-2.svg" />
                  </span>
                </div>
              </div>
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  Order list
                </div>
                <div class="font-size-lg text-muted font-weight-bold mt-1">
                  turnover : 36.351 (20%)<br>
                  profit : 12.351 (33%)<br>                  
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-center mr-2">
              <div
                class="symbol symbol-45 symbol-light-primary mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-primary">
                    <inline-svg
                      src="media/svg/icons/Shopping/Box3.svg"
                    />
                  </span>
                </div>
              </div>
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  Decoration
                </div>
                <div class="font-size-lg text-muted font-weight-bold mt-1">
                  turnover : 36.351 (10%)<br>
                  profit : 12.351 (33%)<br>                  
                </div>
              </div>
            </div>
          </div>
        </div> -->
        
      </div>
    </div>    
    <!--end::Body-->

        <div class="row m-0">
    <div class="table-responsive">
      <!-- Start of loading animation -->
      <div class="p-10" v-if="loading">
      <!-- <div class="card-body"> -->
      <div class="spinner spinner-primary spinner-md spinner-center"></div>
      <p class="text-6xl font-bold text-center text-gray-500 animate-pulse mt-5">
        Loading...
      </p>
      <!-- </div> -->
      <!-- End of loading animation -->
      <!-- Start of error alert -->
      <v-alert border="top" color="red lighten-2" v-if="error.length > 0" dark>
        {{ error.response }}
      </v-alert>
      </div>

      <vue-good-table  v-if="!loading"
        :columns="columns"
        :rows="data"
        styleClass="vgt-table /*striped*/"
        :fixed-header="false"
        compactMode
        :pagination-options="{
          enabled: false,
          perPage: 50,
          mode: 'pages'
        }"  
        :search-options="{
          enabled: false,
          placeholder: 'Search for a customer',
        }">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'margePerc'">            
            <span v-if="props.row.margePerc > 0">{{ props.row.margePerc }} %</span>
            <span v-if="props.row.margePerc < 0" class="text-danger">{{ props.row.margePerc }} %</span>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>    
    </div>
        </div>

      
    <!--begin::Table-->
      <!-- <table
        class="table table-head-custom table-vertical-center"
        id="kt_advance_table_widget_4"
      >
        <thead>
          <tr class="text-left">
            <th class="pl-0" style="width: 55px">Debnr</th>
            <th style="min-width: 140px">
              {{ $t("TRANSLATOR.NAME", { msg: "NAME" }) }}
            </th>
            <th class="pl-0" style="min-width: 50px; max-width: 80px">week</th>
            <th class="pr-0" style="min-width: 100px">Last connection</th>
            <th class="pr-0" style="min-width: 100px">Last buy</th>
            <th class="pl-0 text-right" style="min-width: 50px; max-width: 100px">Days</th>
            <th class="pl-0 text-right" style="min-width: 50px; max-width: 100px">Visited pages</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, i) in data">
            <tr v-bind:key="i">
              <td class="pl-0">
                  {{ item.debnr }}
              </td>
              <td>
                <a
                  v-bind:href="`#/customers/${item.debnr}`"
                  class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                >
                  {{ item.debnaam }}
                </a>
              </td>
              <td class="text-left">
                <span class="text-muted font-weight-bold">{{item.lastWeekVisit}}</span>
              </td>
              <td>
                <span class="text-muted font-size-sm">{{item.logtime}}</span>
              </td>
              <td>
                <span class="text-muted font-size-sm">{{item.lastBuy}}</span>
              </td>
              <td class="pr-0 text-right">
                <span v-if="item.diffDate == 0 && item.diffDate != ''" class="svg-icon"><i class="flaticon2-check-mark text-success"></i></span>
                <span v-if="item.diffDate == ''" class="svg-icon"><i class="flaticon-cancel text-warning"></i></span>
                <span v-if="item.diffDate != 0" class="text-muted font-weight-bold">{{ item.diffDate }}</span>
              </td>
              <td class="text-right">
                <span class="text-muted font-weight-bold">{{item.pages}}</span>
              </td>
              <td class="pr-0 text-right">
                <span class="label label-lg label-inline" v-bind:class="`label-light-${item.class}`">{{ item.status }}</span>
              </td> -->
            <!-- </tr>
          </template>
        </tbody>
      </table> -->
  </div>
</template>

<script>
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';


export default {
  // name: "line-base",
  components: {
    // MixedWidget1,
    VueGoodTable,
  },  
  data() {
    return {
      loading: true,
      startdate: null,
      enddate: null,
      dates: [this.startdate, this.enddate],
      error: [],
      headerdata: [],
      data: [],
      columns: [
        {
          label: 'Debnr',
          field: 'debnr',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-hover-primary font-size-lg',
          // width: '100px',
          filterOptions: {
          // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter', // placeholder for filter input
            // filterValue: 'Jane', // initial populated value for this filter
            // filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, //custom filter function that
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Selling',
          field: 'verkoop',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-dark-75 font-weight-bolder text-hover-primary font-size-lg',
          formatFn: this.formatNumbers,
          // width: '100px',
        },
        {
          label: 'Buying',
          field: 'inkoop',
          type: 'number',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          formatFn: this.formatNumbers,
          // type: 'date',
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'MMM do yy',
        },
        {
          label: 'Margin %',
          field: 'margePerc',
          type: 'number',
          // sortable: false,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          tooltip: 'A simple tooltip',
        },
        {
          label: 'Flowers',
          field: 'flowers',
          type: 'number',
          // sortable: false,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          formatFn: this.formatNumbers,
        },
        {
          label: 'Plants',
          field: 'plants',
          type: 'number',
          // sortable: false,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          formatFn: this.formatNumbers,
        },
        {
          label: 'Deco',
          field: 'deco',
          type: 'number',
          // sortable: false,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          formatFn: this.formatNumbers,
        },
        {
          label: 'Order list',
          field: 'orderList',
          type: 'number',
          // sortable: false,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          formatFn: this.formatNumbers,
        },
      ],
    }
  },
  mounted() {
    // console.log(this.$route.params);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Home > Turnover"}]);
    this.startdate = (new Date(Date.now()-86400000)).toISOString().substr(0, 10);
    this.enddate = (new Date(Date.now())).toISOString().substr(0, 10);
    this.dates = [this.startdate, this.enddate];
    this.ApplyDates();
    // console.log(this.dates);
  },
  methods: {
    formatNumbers: function(value) {
      return value.toLocaleString('nl-NL');
    },
    thisMonth: function() {
      this.startdate = (new Date(new Date().getFullYear(), new Date().getMonth(), 2)).toISOString().substr(0, 10);
      this.enddate = (new Date(Date.now())).toISOString().substr(0, 10);
      this.dates = [this.startdate, this.enddate];
      this.ApplyDates()
    },
    lastMonth: function() {
      this.startdate = (new Date(new Date().getFullYear(), new Date().getMonth()-1, 2)).toISOString().substr(0, 10);
      this.enddate = (new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString().substr(0, 10);
      this.dates = [this.startdate, this.enddate];
      this.ApplyDates()
    },
    ApplyDates() {
    this.loading = true;
    axios
      .get("/home/turnover", {
          params: {
              'startdate' : this.dates[0],
              'enddate' : this.dates[1]
          }
      })
      .then((response) => {
        this.data = response.data;
        // this.loading = false;
      })
      .catch((error) => {
        this.error.response = "Error msg";
        // console.log(error.response)
        this.loading = false;
        throw error;
      });
      
    axios
      .get("/home/headerturnover", {
          params: {
              'startdate' : this.dates[0],
              'enddate' : this.dates[1]
          }
      })
      .then((response) => {
        this.headerdata = response.data;
        this.loading = false;
      })
      .catch((error) => {
        this.error.response = "Error msg";
        // console.log(error.response)
        this.loading = false;
        throw error;
      });
    },
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    totalBuying() {
      return this.headerdata.reduce((acc, item) => acc + item.inkoop, 0);
    },
    totalSelling() {
      return this.headerdata.reduce((acc, item) => acc + item.verkoop, 0);
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}

.navi .navi-header {
    padding: 0;
}

.b-dropdown-text {
    padding: 0;
}

.v-picker__title {
  background-color: #2196f3 !important;
  color: white;
}
.v-date-picker-table .v-btn.v-btn--active {
  background-color: #c7c7c7 !important;
}
</style>